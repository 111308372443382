<template>
  <app-view>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary pb-50 mt-2">
      {{ $t('pages.campaign.title') }}
    </h1>
    <h4
      class="text-ellipsis"
    > {{ $t('pages.campaign.add-edit.title-add') }}</h4>

    <div
      class="mt-2 mb-2"
    >
      <b-row>
        <b-col
          class="mb-1"
        >
          <div
            v-if="loader"
            class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
          >
            <b-spinner
              variant="primary"
              type="grow"
              :label="$t('therms.loading')"
            />
          </div>
          <template v-else>
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="animate__animated"
                :class="{'animate__shakeX':error}"
                @submit.prevent="submit"
              >
                <b-form-group
                  label-size="lg"
                  :label="$t('therms.name')+ ' :'"
                  label-for="name"
                >

                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="nameData"
                      size="lg"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('pages.campaign.add-edit.form.name.placeholder')"
                    />
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label-size="lg"
                  :label="$t('pages.campaign.add-edit.form.start_date.name')+' :'"
                  label-for="start_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="start_date"
                    vid="start_date"
                    :rules="'required'"
                  >
                    <b-form-input
                      id="start_date"
                      v-model="startDateData"
                      name="start_date"
                      size="lg"
                      type="date"
                      :state="errors.length > 0 || !isValidDates ? false:null"
                      :placeholder="$t('pages.campaign.add-edit.form.start_date.placeholder')"
                    />
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label-size="lg"
                  :label="$t('pages.campaign.add-edit.form.end_date.name')+' :'"
                  label-for="end_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="end_date"
                    vid="end_date"
                    :rules="'required'"
                  >
                    <b-form-input
                      id="end_date"
                      v-model="endDateData"
                      size="lg"
                      type="date"
                      :state="errors.length > 0 || !isValidDates > 0 ? false:null"
                      :placeholder="$t('pages.campaign.add-edit.form.end_date.placeholder')"
                    />
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="selectedFarmers.length > 1"
                  label-size="lg"
                  :label="$t('pages.campaign.add-edit.form.farmers.name')+' :'"
                  label-for="farmer"
                >
                  <b-form-checkbox
                    v-for="farmer in selectedFarmers"
                    :key="farmer.id"
                    v-model="farmerIds[farmer.id]"
                    class="mb-1"
                    name="farmer"
                  >
                    {{ farmer.name }}
                  </b-form-checkbox>
                </b-form-group>

                <b-form-checkbox
                  v-model="reservedForApp"
                  class="mt-3"
                >
                  {{ $t('pages.campaign.add-edit.form.reserved-for-app.name') }}
                </b-form-checkbox>

                <div class="mt-2">
                  <touch-button
                    size="sm"
                    :disabled="invalid || error || !isValidDates || !hasSpecifiedFarmerIds"
                    :disable="invalid || error || !isValidDates || !hasSpecifiedFarmerIds"
                  >
                    {{ $t('therms.save') }}
                  </touch-button>
                </div>
              </b-form>
            </validation-observer>
          </template>
        </b-col>
      </b-row>

    </div>
  </app-view>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import {
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BRow, BSpinner,
} from 'bootstrap-vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import { NAME_RESUME_CAMPAIGNS } from '@/router/routes/campaigns'
import { Dialog } from '@capacitor/dialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavButtonBar,
    NavButton,
    BSpinner,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    TouchButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // data
      nameData: '',
      startDateData: '',
      endDateData: '',
      farmerIds: {},
      reservedForApp: false,

      // UI
      loader: false,
      error: false,

      // validation rules
      required,
    }
  },
  computed: {
    ...mapGetters({
      selectedFarmers: 'farmers/getSelectedFarmers',
    }),
    maxStartDate() {
      return this.$moment(this.endDateData)
        .subtract(1, 'days')
        .toDate()
    },
    minEndDate() {
      return this.$moment(this.startDateData)
        .add(1, 'days')
        .toDate()
    },
    isValidDates() {
      return (
        this.startDateData.length === 0
          || this.endDateData.length === 0
      ) || (this.$moment(this.startDateData)
        .isSameOrBefore(this.maxStartDate)
          && this.$moment(this.endDateData)
            .isSameOrAfter(this.minEndDate))
    },
    hasSpecifiedFarmerIds() {
      return Object.keys(this.farmerIds)
        .filter(key => this.farmerIds[key])
        .length > 0
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 800)
    },
    selectedFarmers: {
      handler() {
        if (!this.selectedFarmers) return

        this.selectedFarmers.forEach(farmer => {
          this.$set(this.farmerIds, farmer.id, false)
        })

        if (this.selectedFarmers.length === 1) {
          this.$set(this.farmerIds, this.selectedFarmers[0].id, true)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async submit() {
      if (!this.isValidDates) return
      if (!this.hasSpecifiedFarmerIds) return
      if (this.$moment(this.endDateData)
        .diff(this.$moment(this.startDateData), 'months') > 18) {
        this.error = true
        Dialog.alert({
          title: this.$t('therms.error')
            .toString(),
          message: this.$t('pages.campaign.add-edit.error-modal.message')
            .toString(),
          buttonTitle: this.$t('therms.close')
            .toString(),
        })
          .then()
        return
      }

      try {
        this.loader = true
        const result = await this.$store.dispatch('campaigns/add', {
          name: this.nameData,
          startDate: this.startDateData,
          endDate: this.endDateData,
          farmerIds: Object.keys(this.farmerIds)
            .filter(key => this.farmerIds[key]),
          reservedForApp: this.reservedForApp,
        })

        if (!result) {
          this.error = true
          return
        }

        await this.$router.push({
          name: NAME_RESUME_CAMPAIGNS,
        })

        this.loader = false
      } catch (err) {
        this.error = true
        this.loader = false
        throw err
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

input::-webkit-date-and-time-value {
  margin-top: 0;
  text-align: left;
  width: 100%;
}

</style>
